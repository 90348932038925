import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";
import "../inputs.css";
import { fetchProvinces } from "../../helpers/public";
import { CircularProgress } from "@mui/material";
import { useCookies } from "react-cookie";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 860,
  bgcolor: "background.paper",
  borderRadius: "50px",
  textAlign: "center",
  boxShadow: 24,
  p: "20px 20px 0 20px",
  fontFamily: "Almarai",
};

export default function AddSub({ handleClose, handleClickAlert, setMessage }) {
  // const [paddingTop, setPaddingTop] = useState('150px')
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [univ, setUniv] = useState(true);
  const [data, setData] = useState({
    universities: [],
    collages: [],
    years: [],
    bachelors: [],
    subjects: [],
  });
  const [filterData, setFilterData] = useState({
    universities: [],
    collages: [],
    years: [],
    subjects: [],
  });
  const [formData, setFormData] = useState({
    sub_subject_name: "",
    sub_subject_notes: "",
    subject_id: null,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  React.useEffect(() => {
    const fetchProvinces = async (setData, url) => {
      const token = cookies["token"];
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data);
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    };
    fetchProvinces(
      setData,
      `${process.env.REACT_APP_URL}/houdix/seen/admin/get-all`
    );
  }, []);

  const handleCollagesChange = (e) => {
    setFilterData({
      ...filterData,
      collages: data.collages.filter(
        (item) => item.university_id == e.target.value
      ),
    });
  };
  const handleYearsChange = (e) => {
    setFilterData({
      ...filterData,
      years: data.years.filter((item) => item.collage_id == e.target.value),
    });
  };
  const handleSubjectsYearChange = (e) => {
    setFilterData({
      ...filterData,
      subjects: data.subjects.filter((item) => item.year_id == e.target.value),
    });
  };
  const handleSubjectsChange = (e) => {
    setFilterData({
      ...filterData,
      subjects: data.subjects.filter(
        (item) => item.bachelor_id == e.target.value
      ),
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  console.log(formData);

  const handleFormSubmit = async (e) => {
    const token = cookies["token"];
    setIsLoading(true);
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/houdix/seen/admin/sub-subjects`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setIsLoading(false);
        setMessage("تمت العملية بنجاح");
        handleClose();
        handleClickAlert();
      }
    } catch (error) {
      setMessage("خطأ في العملية");
      handleClose();
      handleClickAlert();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {!isLoading ? (
            <>
              <Typography
                sx={{ fontFamily: "Almarai" }}
                id="modal-modal-title"
                variant="h3"
                component="h2"
              >
                إضافة تصنيف
              </Typography>
              <button
                className={!univ ? "add-active" : "add-btn"}
                type="submit"
                onClick={() => {
                  setUniv(false);
                  setFormData({ ...formData, year_id: null });
                }}
              >
                بكالوريا
              </button>
              <button
                className={univ ? "add-active" : "add-btn"}
                type="button"
                onClick={() => {
                  setUniv(true);
                  setFormData({ ...formData, bachelor_id: null });
                }}
              >
                جامعة
              </button>
              <div
                class="center"
                style={{ paddingTop: "50px", paddingBottom: "20px" }}
              >
                <form onSubmit={handleFormSubmit}>
                  <div className="input-contener">
                    <div class="inputbox">
                      <input
                        dir="rtl"
                        type="text"
                        name="sub_subject_notes"
                        value={formData.sub_subject_notes}
                        onChange={handleInputChange}
                      />
                      <span>ملاحظة</span>
                    </div>
                    <div class="inputbox">
                      <input
                        dir="rtl"
                        type="text"
                        name="sub_subject_name"
                        value={formData.sub_subject_name}
                        onChange={handleInputChange}
                        required
                        autoFocus
                      />
                      <span>الاسم</span>
                    </div>
                    {univ ? (
                      <>
                        <div class="inputbox">
                          <select
                            dir="rtl"
                            name="university_id"
                            onChange={handleCollagesChange}
                            required
                          >
                            <option value="">اختر جامعة</option>
                            {data.universities.map((university) => (
                              <option key={university.id} value={university.id}>
                                {university.university_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div class="inputbox">
                          <select
                            dir="rtl"
                            name="collage_id"
                            onChange={handleYearsChange}
                            required
                          >
                            <option value="">اختر كلية</option>
                            {filterData.collages.map((collage) => (
                              <option key={collage.id} value={collage.id}>
                                {collage.collage_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div class="inputbox">
                          <select
                            dir="rtl"
                            name="year_id"
                            onChange={handleSubjectsYearChange}
                            required
                          >
                            <option value="">اختر سنة</option>
                            {filterData.years.map((year) => (
                              <option key={year.id} value={year.id}>
                                {year.year_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    ) : (
                      <div class="inputbox">
                        <select
                          dir="rtl"
                          name="bachelor_id"
                          onChange={handleSubjectsChange}
                          required
                        >
                          <option value="">اختر فرع</option>
                          {data.bachelors.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.bachelor_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    <div class="inputbox">
                      <select
                        dir="rtl"
                        name="subject_id"
                        value={formData.subject_id}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">اختر مادة</option>
                        {filterData.subjects.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.subject_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="inputbox">
                    <button type="submit" disabled={isSubmitting}>
                      موافق
                    </button>
                    <button type="button" onClick={handleClose}>
                      إلغاء
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                height: "300px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
