import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import '../inputs.css';
// import { fetchProvinces } from '../../helpers/public';
import { CircularProgress, MenuItem, TextField } from '@mui/material';
import { fetchProvinces } from '../../helpers/public';
import { useCookies } from 'react-cookie';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 860,
    bgcolor: 'background.paper',
    borderRadius: '50px',
    textAlign: 'center',
    boxShadow: 24,
    p: '20px 20px 0 20px',
    fontFamily: 'Almarai',
};

export default function EditCode({ handleClose, handleClickAlert, selectedRows, setMessage }) {

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    // const [paddingTop, setPaddingTop] = useState('320px')
    const [isLoading, setIsLoading] = useState(true);
    const [code, setCode] = useState({});
    const [data, setData] = useState({
        universities: [],
        collages: [],
        years: [],
        bachelors: [],
        subjects: [],
    });
    const [filterData, setFilterData] = useState({
        universities: [],
        collages: [],
        years: [],
        subjects: [],
    });
    const [formData, setFormData] = useState({
        university_id: code['subjects.year.collage.university.id'],
        collage_id: code['subjects.year.collage.id'],
        year_id: code['subjects.year.id'],
        bachelor_id: code["subjects.bachelor.id"],
        code_name: code.code_name,
        code_notes: code.code_notes,
        expiry_time: code.expiry_time,
        price: code.price,
        m_university: code.m_university,
        subjects_ids: selectedRows.subjects_id,
    });
    
    const [isSubmitting, setIsSubmitting] = useState(false);

    React.useEffect(() => {
        const fetchProvinces = async (setData, url) => {
            const token = cookies['token']
            try {
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching provinces:', error);
            }
        };
        fetchProvinces(setData, `${process.env.REACT_APP_URL}/houdix/seen/admin/get-all`);
        const fetchProvinces2 = async () => {
            const token = cookies['token']
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/houdix/seen/admin/codes/${selectedRows.id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setIsLoading(false);
                setCode(response.data);
            } catch (error) {
                console.error('Error fetching provinces:', error);
            }
        };
        fetchProvinces2();
    }, []);

    const handleCollagesChange = (e) => {
        setFilterData({
            ...filterData,
            collages: data.collages.filter(item => item.university_id == e.target.value)
        });
        handleInputChange(e);
    };
    const handleYearsChange = (e) => {
        setFilterData({
            ...filterData,
            years: data.years.filter(item => item.collage_id == e.target.value)
        });
        handleInputChange(e);
    };
    const handleSubjectsYearChange = (e) => {
        setFilterData({
            ...filterData,
            subjects: data.subjects.filter(item => item.year_id == e.target.value)
        });
        handleInputChange(e);
    };
    const handleSubjectsChange = (e) => {
        setFilterData({
            ...filterData,
            subjects: data.subjects.filter(item => item.bachelor_id == e.target.value)
        });
        handleInputChange(e);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCode({
            ...code,
            [name]: value,
        });
    };

    const handleInputSubjectsChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: typeof value === "string" ? value.split(',') : value,
        });
    };

    const handleFormSubmit = async (e) => {
        const token = cookies['token']
        setIsLoading(true);
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await axios.put(`${process.env.REACT_APP_URL}/houdix/seen/admin/codes/${selectedRows.id}`, { code: code, formData: formData.subjects_ids }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setIsLoading(false);
                setMessage("تمت العملية بنجاح")
                handleClose()
                handleClickAlert()
            }
        } catch (error) {
            console.log(error);
            setMessage("خطأ في العملية")
            handleClose()
            handleClickAlert()
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {!isLoading ? (
                        <>
                            <Typography sx={{ fontFamily: 'Almarai' }} id="modal-modal-title" variant="h3" component="h2">
                                تعديل كود
                            </Typography>
                            {/* <div className="center" style={{ paddingTop: "40px" }}>
                                <form onSubmit={handleFormSubmit}>
                                    <div className="inputbox">
                                        <input
                                            dir='rtl'
                                            type="text"
                                            name="code_name"
                                            value={formData.code_name}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <span>الاسم</span>
                                    </div>
                                    <div className="inputbox">
                                        <input
                                            dir='rtl'
                                            type="text"
                                            name="code_notes"
                                            value={formData.code_notes}
                                            onChange={handleInputChange}
                                        />
                                        <span>ملاحظة</span>
                                    </div>
                                    <div className="inputbox">
                                        <input
                                            dir='rtl'
                                            type="number"
                                            name="expiry_time"
                                            value={formData.expiry_time}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <span>المدة</span>
                                    </div>
                                    <div className="inputbox">
                                        <input
                                            dir='rtl'
                                            type="number"
                                            name="price"
                                            value={formData.price}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <span>السعر</span>
                                    </div>
                                    <div className="inputbox">
                                        <button type="submit" disabled={isSubmitting}>
                                            موافق
                                        </button>
                                        <button type="button" onClick={handleClose}>
                                            إلغاء
                                        </button>
                                    </div>
                                </form>
                            </div> */}
                            <div class="center" style={{ paddingTop: "60px" }}>
                                <form onSubmit={handleFormSubmit}>
                                    <div className='input-contener'>
                                        <div class="inputbox">
                                            <input
                                                dir='rtl'
                                                type="text"
                                                name="code_name"
                                                value={code.code_name}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <span>الاسم</span>
                                        </div>
                                        <div class="inputbox">
                                            <input
                                                dir='rtl'
                                                type="text"
                                                name="code_notes"
                                                value={code.code_notes}
                                                onChange={handleInputChange}
                                            />
                                            <span>ملاحظة</span>
                                        </div>
                                        <div class="inputbox">
                                            <input
                                                dir='rtl'
                                                type="text"
                                                name="expiry_time"
                                                value={code.expiry_time}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <span>المدة</span>
                                        </div>
                                        <div class="inputbox">
                                            <input
                                                dir='rtl'
                                                type='number'
                                                name="price"
                                                value={code.price}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <span>السعر</span>
                                        </div>
                                        {code["subjects.year.id"] ? (
                                            <>
                                                <div class="inputbox">
                                                    <select
                                                        dir='rtl'
                                                        name="subjects.year.collage.university.id"
                                                        value={code["subjects.year.collage.university.id"]}
                                                        onChange={handleCollagesChange}
                                                        onClick={handleCollagesChange}
                                                        required
                                                    >
                                                        <option value="">اختر جامعة</option>
                                                        {data.universities.map((university) => (
                                                            <option key={university.id} value={university.id}>
                                                                {university.university_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div class="inputbox">
                                                    <select
                                                        dir='rtl'
                                                        name="subjects.year.collage.id"
                                                        value={code["subjects.year.collage.id"]}
                                                        onChange={handleYearsChange}
                                                        onClick={handleYearsChange}
                                                        required
                                                    >
                                                        <option value="">إختر كلية</option>
                                                        {filterData.collages.map((collage) => (
                                                            <option key={collage.id} value={collage.id}>
                                                                {collage.collage_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div class="inputbox">
                                                    <select
                                                        dir='rtl'
                                                        name="subjects.year.id"
                                                        value={code["subjects.year.id"]}
                                                        onChange={handleSubjectsYearChange}
                                                        onClick={handleSubjectsYearChange}
                                                        required
                                                    >
                                                        <option value="">إختر سنة</option>
                                                        {filterData.years.map((year) => (
                                                            <option key={year.id} value={year.id}>
                                                                {year.year_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="inputbox">
                                                    <select
                                                        dir='rtl'
                                                        name="m_university"
                                                        onChange={handleInputChange}
                                                        value={code.m_university}
                                                        required
                                                    >
                                                        <option value="">جامعتي</option>
                                                        {data.universities.map((item) => (
                                                            <option key={item.id} value={item.university_name}>
                                                                {item.university_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className='inputboxs'>
                                                    <TextField
                                                        dir='rtl'
                                                        select
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    border: '4px solid #240259',
                                                                    borderRadius: '10px',
                                                                    height: '65px' // Remove the outer border
                                                                },
                                                                '&:hover fieldset': {
                                                                    border: '4px solid #240259', // Remove the hover border
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    border: '4px solid #240259', // Remove the focused border
                                                                },
                                                            },
                                                        }}
                                                        name='subjects_ids'
                                                        label='Subject'
                                                        value={formData.subjects_ids}
                                                        className='material'
                                                        onChange={handleInputSubjectsChange}
                                                        SelectProps={{
                                                            multiple: true,
                                                        }}
                                                        placeholder='sadasdasd'
                                                    >
                                                        {filterData.subjects.map((item) => (
                                                            <MenuItem
                                                                key={item.id}
                                                                value={item.id}
                                                            >
                                                                {item.subject_name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </div>
                                            </>
                                        ) : (
                                            <>

                                                <div class="inputbox">
                                                    <select
                                                        dir='rtl'
                                                        name="subjects.bachelor.id"
                                                        value={code["subjects.bachelor.id"]}
                                                        onChange={handleSubjectsChange}
                                                        onClick={handleSubjectsChange}
                                                        required
                                                    >
                                                        <option value="">إختر فرع</option>
                                                        {data.bachelors.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.bachelor_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className='inputboxs'>
                                                    <TextField
                                                        dir='rtl'
                                                        select
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    border: '4px solid #240259',
                                                                    borderRadius: '10px',
                                                                    height: '65px' // Remove the outer border
                                                                },
                                                                '&:hover fieldset': {
                                                                    border: '4px solid #240259', // Remove the hover border
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    border: '4px solid #240259', // Remove the focused border
                                                                },
                                                            },
                                                        }}
                                                        name='subjects_ids'
                                                        label='Subject'
                                                        value={formData.subjects_ids}
                                                        className='material'
                                                        onChange={handleInputSubjectsChange}
                                                        SelectProps={{
                                                            multiple: true,
                                                        }}
                                                        placeholder='sadasdasd'
                                                    >
                                                        {filterData.subjects.map((item) => (
                                                            <MenuItem
                                                                key={item.id}
                                                                value={item.id}
                                                            >
                                                                {item.subject_name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div class="inputbox">
                                        <button type="submit" disabled={isSubmitting}>
                                            موافق
                                        </button>
                                        <button type="button" onClick={handleClose}>
                                            إلغاء
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </>
                    ) : (
                        <div style={{ display: 'flex', height: "300px", justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
}