import React, { useEffect, useState } from "react";
import "../table.css";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import EditStudents from "./EditStudent";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import Transfer from "./Transfer";
import Export from "./Export";
import { fetchData, handleDeleteActionClick } from "../../helpers/public";
import { studentDataGrid } from "../../constant/dataGrid";
import { useCookies } from "react-cookie";

const url = `${process.env.REACT_APP_URL}/houdix/seen/admin/users`;
const messages = {
  a: "الرجاء اختيار مستخدمين للحذف",
  c: "هل انت متاكد انك تريد الحذف",
  b: "خطأ في العملية",
};

const Students = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const [open, setOpen] = React.useState(false);
  const [addOpen, setAddOpen] = React.useState(false);
  const [exportOpen, setExportOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");

  const handleAddOpen = () => setAddOpen(true);
  const handleExportOpen = () => setExportOpen(true);
  const handleExportClose = () => setExportOpen(false);
  const handleAddClose = () => setAddOpen(false);
  const handleEditClose = () => setEditOpen(false);
  const handleEditOpen = () => setEditOpen(true);

  const handleAddActionClick = () => {
    handleAddOpen();
  };

  const handleExportActionClick = (row) => {
    handleExportOpen();
  };

  const handleEditActionClick = (row) => {
    setSelectedItem(row);
    handleEditOpen();
  };

  const handleClickAlert = () => {
    setOpen(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleDeleteActionClick = (
    selectedRows,
    setMessage,
    handleClickAlert,
    messages,
    url
  ) => {
    const token = cookies["token"];
    if (selectedRows.length === 0) {
      alert(messages.a);
      return;
    }
    const confirmation = window.confirm(messages.c);
    if (confirmation) {
      axios
        .delete(url, {
          data: { ids: selectedRows },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setMessage("تمت العملية بنجاح");
            handleClickAlert();
          }
        })
        .catch((error) => {
          console.log(error);
          setMessage(messages.b);
          handleClickAlert();
        });
    }
  };

  useEffect(() => {
    const token = cookies["token"];
    // fetchData(url, setRows, setIsLoading, cookies);
    const fetchData = async (url, setRows, setIsLoading, setCsvRows, token) => {
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRows(response.data);
        setIsLoading(false);
        if (setCsvRows) {
          setCsvRows(setCsvRows.token);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetchData(url, setRows, setIsLoading, cookies, token);
  }, [open]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="title">
          <div>
            <button onClick={handleAddActionClick}>نقل</button>
            <button onClick={handleExportActionClick}>CSV</button>
            {selectedRows.length !== 0 && (
              <button
                onClick={() => {
                  handleDeleteActionClick(
                    selectedRows,
                    setMessage,
                    handleClickAlert,
                    messages,
                    url
                  );
                }}
              >
                حذف
              </button>
            )}
          </div>
          <div>
            <span className="main-title">المستخدمون</span>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ height: "calc(100vh - 130px)", width: "100%" }}>
              <DataGrid
                sx={{
                  ".MuiDataGrid-columnHeaderTitle": { fontWeight: "700" },
                  ".MuiSvgIcon-root": { color: "#240259" },
                  ".MuiTablePagination-root": { direction: "ltr" },
                }}
                hideFooter={false}
                rows={rows}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 9,
                    },
                  },
                }}
                columns={studentDataGrid(
                  ImportExportIcon,
                  handleEditActionClick
                )}
                checkboxSelection
                pageSizeOptions={[9]}
                onRowSelectionModelChange={(itm) => setSelectedRows(itm)}
                disableRowSelectionOnClick
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even"
                }
                style={{
                  border: "5px solid #DFDFDF",
                  borderRadius: "32px 32px 0 0 ",
                  direction: "rtl",
                  borderBottom: "none",
                  backgroundColor: "#FFFFFF",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {addOpen && (
        <Transfer
          handleClose={handleAddClose}
          handleClickAlert={handleClickAlert}
          selectedRows={selectedRows}
        />
      )}
      {exportOpen && (
        <Export
          handleClose={handleExportClose}
          handleClickAlert={handleClickAlert}
          selectedRows={selectedRows}
        />
      )}
      {editOpen && (
        <EditStudents
          handleClose={handleEditClose}
          selectedRows={selectedItem}
          handleClickAlert={handleClickAlert}
        />
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: "100%", backgroundColor: "#240259", color: "white" }}
        >
          تمت العملية بنجاح
        </Alert>
      </Snackbar>
    </>
  );
};

export default Students;
