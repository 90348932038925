import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";
import "../inputs.css";
import { fetchProvinces } from "../../helpers/public";
import { CircularProgress } from "@mui/material";
import { useCookies } from "react-cookie";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: "50px",
  textAlign: "center",
  boxShadow: 24,
  p: "50px 20px",
  fontFamily: "Almarai",
};

export default function Transfer({
  handleClose,
  handleClickAlert,
  selectedRows,
}) {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [univ, setUniv] = useState(true);
  const [data, setData] = useState({
    universities: [],
    collages: [],
    years: [],
    bachelors: [],
  });
  const [filterData, setFilterData] = useState({
    universities: [],
    collages: [],
    years: [],
  });
  const [formData, setFormData] = useState({
    year_id: null,
    ids: selectedRows,
    bachelor_id: null,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  React.useEffect(() => {
    const fetchProvinces = async (setData, url) => {
      const token = cookies["token"];
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data);
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    };
    fetchProvinces(
      setData,
      `${process.env.REACT_APP_URL}/houdix/seen/admin/get-all`
    );
  }, []);

  const handleCollagesChange = (e) => {
    setFilterData({
      ...filterData,
      collages: data.collages.filter(
        (item) => item.university_id == e.target.value
      ),
    });
  };

  const handleYearsChange = (e) => {
    setFilterData({
      ...filterData,
      years: data.years.filter((item) => item.collage_id == e.target.value),
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    const token = cookies["token"];
    setIsLoading(true);
    e.preventDefault();
    setIsSubmitting(true);
    if (selectedRows.length === 0) {
      alert("الرجاء اختيار مستخدمين");
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/houdix/seen/admin/users`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setIsLoading(false);
        handleClose();
        handleClickAlert();
      }
    } catch (error) {
      console.error("Error posting university:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {!isLoading ? (
            <>
              <Typography
                sx={{ fontFamily: "Almarai" }}
                id="modal-modal-title"
                variant="h3"
                component="h2"
              >
                نقل المستخدمين
              </Typography>
              <button
                type="submit"
                className={!univ ? "add-active" : "add-btn"}
                onClick={() => {
                  setUniv(false);
                  setFormData({ ...formData, year_id: null });
                }}
              >
                بكالوريا
              </button>
              <button
                type="button"
                className={univ ? "add-active" : "add-btn"}
                onClick={() => {
                  setUniv(true);
                  setFormData({ ...formData, bachelor_id: null });
                }}
              >
                جامعة
              </button>
              <div class="center">
                <form onSubmit={handleFormSubmit}>
                  {univ ? (
                    <>
                      <div class="inputbox">
                        <select
                          dir="auto"
                          name="university_id"
                          onChange={handleCollagesChange}
                          required
                        >
                          <option value="">اختر جامعة</option>
                          {data.universities.map((university) => (
                            <option key={university.id} value={university.id}>
                              {university.university_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="inputbox">
                        <select
                          dir="auto"
                          name="collage_id"
                          onChange={handleYearsChange}
                          required
                        >
                          <option value="">اختر كلية</option>
                          {filterData.collages.map((collage) => (
                            <option key={collage.id} value={collage.id}>
                              {collage.collage_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="inputbox">
                        <select
                          dir="auto"
                          name="year_id"
                          value={formData.year_id}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">اختر سنة</option>
                          {filterData.years.map((year) => (
                            <option key={year.id} value={year.id}>
                              {year.year_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </>
                  ) : (
                    <div class="inputbox">
                      <select
                        dir="auto"
                        name="bachelor_id"
                        value={formData.bachelor_id}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">اختر فرع</option>
                        {data.bachelors.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.bachelor_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div class="inputbox">
                    <button type="submit" disabled={isSubmitting}>
                      موافق
                    </button>
                    <button type="button" onClick={handleClose}>
                      إلغاء
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                height: "300px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
