import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import '../inputs.css';
import { fetchProvinces } from '../../helpers/public';
import { CircularProgress } from '@mui/material';
import { useCookies } from 'react-cookie';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 860,
    bgcolor: 'background.paper',
    borderRadius: '50px',
    textAlign: 'center',
    boxShadow: 24,
    p: '20px 20px 0 20px',
    fontFamily: 'Almarai',
};

export default function AddSubjects({ handleClose, handleClickAlert, setMessage }) {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    // const [paddingTop, setPaddingTop] = useState('100px')
    const [isLoading, setIsLoading] = React.useState(false);
    const [univ, setUniv] = useState("univ")
    const [data, setData] = useState({
        universities: [],
        collages: [],
        years: [],
        bachelors: [],
    });
    const [filterData, setFilterData] = useState({
        universities: [],
        collages: [],
        years: [],
    });
    const [formData, setFormData] = useState({
        year_id: null,
        subject_name: '',
        subject_notes: '',
        bachelor_id: null,
        term: "",
        language: "",
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    React.useEffect(() => {
        const fetchProvinces = async (setData, url) => {
            const token = cookies['token']
            try {
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching provinces:', error);
            }
        };
        fetchProvinces(setData, `${process.env.REACT_APP_URL}/houdix/seen/admin/get-all`);
    }, []);

    const handleCollagesChange = (e) => {
        setFilterData({
            ...filterData,
            collages: data.collages.filter(item => item.university_id == e.target.value)
        })
    };
    const handleYearsChange = (e) => {
        setFilterData({
            ...filterData,
            years: data.years.filter(item => item.collage_id == e.target.value)
        })
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFormSubmit = async (e) => {
        const token = cookies['token'];
        setIsLoading(true);
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/houdix/seen/admin/subjects`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setIsLoading(false);
                setMessage("تمت العملية بنجاح")
                handleClose()
                handleClickAlert()
            }
        } catch (error) {
            setMessage("خطأ في العملية")
            handleClose()
            handleClickAlert()
        } finally {
            setIsSubmitting(false);
        }
    };

    const handelTahChange = async () => {
        setIsLoading(true);
        const token = cookies['token'];
        setIsSubmitting(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/houdix/seen/admin/subjects/tah`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setIsLoading(false);

                setMessage("تمت العملية بنجاح")
                handleClose()
                handleClickAlert()
            }
        } catch (error) {
            setMessage("اختر سنة اساسية")
            handleClose()
            handleClickAlert()
        } finally {
            setIsSubmitting(false);
        }
    };

    const handelSadChange = async () => {
        setIsLoading(true);
        const token = cookies['token'];
        setIsSubmitting(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/houdix/seen/admin/subjects/sad`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setIsLoading(false);

                setMessage("تمت العملية بنجاح")
                handleClose()
                handleClickAlert()
            }
        } catch (error) {
            console.error(error)
            setMessage("اختر سنة اساسية")
            handleClose()
            handleClickAlert()
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {!isLoading ? (
                        <>
                            <Typography sx={{ fontFamily: 'Almarai' }} id="modal-modal-title" variant="h3" component="h2">
                                إضافة مادة
                            </Typography>
                            <button className={univ === "bac" ? "add-active" : "add-btn"} type="submit" onClick={() => { setUniv("bac"); setFormData({ ...formData, year_id: null }); }} >
                                بكالوريا
                            </button>
                            <button className={univ === "univ" ? "add-active" : "add-btn"} type="button" onClick={() => { setUniv("univ"); setFormData({ ...formData, bachelor_id: null }); }}>
                                جامعة
                            </button>
                            <button className={univ === "tah" ? "add-active" : "add-btn"} type="button" onClick={() => { setUniv("tah"); setFormData({ ...formData, bachelor_id: null, year_id: null }); }}>
                                تحضيرية
                            </button>
                            <button className={univ === "sad" ? "add-active" : "add-btn"} type="button" onClick={() => { setUniv("sad"); setFormData({ ...formData, bachelor_id: null, year_id: null }); }}>
                                سادسة
                            </button>
                            <div class="center" style={{ paddingTop: "50px", paddingBottom: "20px" }}>
                                <form onSubmit={handleFormSubmit}>
                                    {univ === "univ" ? (
                                        <>
                                            <div className='input-contener'>
                                                <div class="inputbox">
                                                    <input
                                                        dir='rtl'
                                                        type="text"
                                                        name="subject_notes"
                                                        value={formData.subject_notes}
                                                        onChange={handleInputChange}
                                                    // required
                                                    />
                                                    <span>ملاحظة</span>
                                                </div>
                                                <div class="inputbox">
                                                    <input
                                                        dir='rtl'
                                                        type="text"
                                                        name="subject_name"
                                                        value={formData.subject_name}
                                                        onChange={handleInputChange}
                                                        required
                                                        autoFocus
                                                    />
                                                    <span>الاسم</span>
                                                </div>
                                                <div class="inputbox">
                                                    {/* <input
                                                        dir='rtl'
                                                        type="text"
                                                        name="term"
                                                        value={formData.term}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    <span>الفصل</span> */}
                                                    <select
                                                        dir='rtl'
                                                        name="term"
                                                        value={formData.term}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">اختر فصل</option>
                                                        <option value="الأول">الأول</option>
                                                        <option value="الثاني">الثاني</option>
                                                    </select>
                                                </div>
                                                <div class="inputbox">
                                                    <select
                                                        dir='rtl'
                                                        name="language"
                                                        value={formData.language}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">اختر لغة</option>
                                                        <option value="اجنبي">أجنبي</option>
                                                        <option value="عربي">عربي</option>
                                                    </select>
                                                </div>
                                                <div class="inputbox">
                                                    <select
                                                        dir='rtl'
                                                        name="collage_id"
                                                        onChange={handleYearsChange}
                                                        required
                                                    >
                                                        <option value="">اختر كلية</option>
                                                        {filterData.collages.map((collage) => (
                                                            <option key={collage.id} value={collage.id}>
                                                                {collage.collage_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div class="inputbox">
                                                    <select
                                                        dir='rtl'
                                                        name="university_id"
                                                        onChange={handleCollagesChange}
                                                        required
                                                    >
                                                        <option value="">اختر جامعة</option>
                                                        {data.universities.map((university) => (
                                                            <option key={university.id} value={university.id}>
                                                                {university.university_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div class="inputbox">
                                                    <select
                                                        dir='rtl'
                                                        name="year_id"
                                                        value={formData.year_id}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">اختر سنة</option>
                                                        {filterData.years.map((year) => (
                                                            <option key={year.id} value={year.id}>
                                                                {year.year_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="inputbox">
                                                <button type="submit" disabled={isSubmitting}>
                                                    موافق
                                                </button>
                                                <button type="button" onClick={handleClose}>
                                                    إلغاء
                                                </button>
                                            </div>
                                        </>
                                    ) : univ === "bac" ? (
                                        <>
                                            <div className='input-contener'>
                                                <div class="inputbox">
                                                    <input
                                                        dir='rtl'
                                                        type="text"
                                                        name="subject_notes"
                                                        value={formData.subject_notes}
                                                        onChange={handleInputChange}
                                                    // required
                                                    />
                                                    <span>ملاحظة</span>
                                                </div>
                                                <div class="inputbox">
                                                    <input
                                                        dir='rtl'
                                                        type="text"
                                                        name="subject_name"
                                                        value={formData.subject_name}
                                                        onChange={handleInputChange}
                                                        required
                                                        autoFocus
                                                    />
                                                    <span>الاسم</span>
                                                </div>
                                                <div class="inputbox">
                                                    {/* <input
                                                        dir='rtl'
                                                        type="text"
                                                        name="term"
                                                        value={formData.term}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    <span>الفصل</span> */}
                                                    <select
                                                        dir='rtl'
                                                        name="term"
                                                        value={formData.term}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">اختر فصل</option>
                                                        <option value="الأول">الأول</option>
                                                        <option value="الثاني">الثاني</option>
                                                    </select>
                                                </div>
                                                <div class="inputbox">
                                                    <select
                                                        dir='rtl'
                                                        name="language"
                                                        value={formData.language}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">اختر لغة</option>
                                                        <option value="اجنبي">أجنبي</option>
                                                        <option value="عربي">عربي</option>
                                                    </select>
                                                </div>
                                                <div class="inputbox">
                                                    <select
                                                        dir='rtl'
                                                        name="bachelor_id"
                                                        value={formData.bachelor_id}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">اختر فرع</option>
                                                        {data.bachelors.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.bachelor_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="inputbox">
                                                <button type="submit" disabled={isSubmitting}>
                                                    موافق
                                                </button>
                                                <button type="button" onClick={handleClose}>
                                                    إلغاء
                                                </button>
                                            </div>
                                        </>
                                    ) : univ === "tah" ? (
                                        <>
                                            <div className='input-contener'>
                                                <div class="inputbox">
                                                    <input
                                                        dir='rtl'
                                                        type="text"
                                                        name="subject_notes"
                                                        value={formData.subject_notes}
                                                        onChange={handleInputChange}
                                                    // required
                                                    />
                                                    <span>ملاحظة</span>
                                                </div>
                                                <div class="inputbox">
                                                    <input
                                                        dir='rtl'
                                                        type="text"
                                                        name="subject_name"
                                                        value={formData.subject_name}
                                                        onChange={handleInputChange}
                                                        required
                                                        autoFocus
                                                    />
                                                    <span>الاسم</span>
                                                </div>
                                                <div class="inputbox">
                                                    {/* <input
                                                        dir='rtl'
                                                        type="text"
                                                        name="term"
                                                        value={formData.term}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    <span>الفصل</span> */}
                                                    <select
                                                        dir='rtl'
                                                        name="term"
                                                        value={formData.term}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">اختر فصل</option>
                                                        <option value="الأول">الأول</option>
                                                        <option value="الثاني">الثاني</option>
                                                    </select>
                                                </div>
                                                <div class="inputbox">
                                                    <select
                                                        dir='rtl'
                                                        name="language"
                                                        value={formData.language}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">اختر لغة</option>
                                                        <option value="اجنبي">أجنبي</option>
                                                        <option value="عربي">عربي</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="inputbox">
                                                <button onClick={handelTahChange} disabled={isSubmitting}>
                                                    موافق
                                                </button>
                                                <button type="button" onClick={handleClose}>
                                                    إلغاء
                                                </button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='input-contener'>
                                                <div class="inputbox">
                                                    <input
                                                        dir='rtl'
                                                        type="text"
                                                        name="subject_notes"
                                                        value={formData.subject_notes}
                                                        onChange={handleInputChange}
                                                    // required
                                                    />
                                                    <span>ملاحظة</span>
                                                </div>
                                                <div class="inputbox">
                                                    <input
                                                        dir='rtl'
                                                        type="text"
                                                        name="subject_name"
                                                        value={formData.subject_name}
                                                        onChange={handleInputChange}
                                                        required
                                                        autoFocus
                                                    />
                                                    <span>الإسم</span>
                                                </div>
                                                <div class="inputbox">
                                                    {/* <input
                                                        dir='rtl'
                                                        type="text"
                                                        name="term"
                                                        value={formData.term}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    <span>الفصل</span> */}
                                                    <select
                                                        dir='rtl'
                                                        name="term"
                                                        value={formData.term}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">اختر فصل</option>
                                                        <option value="الأول">الأول</option>
                                                        <option value="الثاني">الثاني</option>
                                                    </select>
                                                </div>
                                                <div class="inputbox">
                                                    <select
                                                        dir='rtl'
                                                        name="language"
                                                        value={formData.language}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">اختر لغة</option>
                                                        <option value="اجنبي">أجنبي</option>
                                                        <option value="عربي">عربي</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="inputbox">
                                                <button onClick={handelSadChange} disabled={isSubmitting}>
                                                    موافق
                                                </button>
                                                <button type="button" onClick={handleClose}>
                                                    إلغاء
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </form>
                            </div>
                        </>
                    ) : (
                        <div style={{ display: 'flex', height: "300px", justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
}