import React, { useEffect, useState } from 'react'
import '../table.css'
import { DataGrid } from '@mui/x-data-grid'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import axios from 'axios';
import { Alert, CircularProgress, Snackbar } from '@mui/material';
import AddCode from './AddCode';
import EditCodes from './EditCode';
import { PDFDownloadLink } from '@react-pdf/renderer';
import QrCode from '../QrCode';
import QRCode from 'qrcode';
import { CSVLink } from 'react-csv';
import { fetchData, handleDeleteActionClick } from '../../helpers/public';
import { codeDataGrid, codeHeaders } from '../../constant/dataGrid';
import { useCookies } from 'react-cookie';

const messages = {
    a: "الرجاء اختيار كود",
    c: "هل انت متاكد انك تريد الحذف",
    b: 'خطأ في العملية'
}
const url = `${process.env.REACT_APP_URL}/houdix/seen/admin/codes`


const Codes = () => {

    const [cookies, setCookie, removeCookie] = useCookies(['token']);


    const [message, setMessage] = useState('')
    const [qrCodeValues, setQRCodeValues] = useState([]);
    const [qrCodeUrl, setQRCodeUrl] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [addOpen, setAddOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [csvRows, setCsvRows] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleAddOpen = () => setAddOpen(true);
    const handleAddClose = () => setAddOpen(false);
    const handleEditOpen = () => setEditOpen(true);
    const handleEditClose = () => setEditOpen(false);

    const handleAddActionClick = () => {
        handleAddOpen();
    };

    const handleEditActionClick = (row) => {
        setSelectedItem(row)
        handleEditOpen();
    };

    const handleClickAlert = () => {
        setOpen(true);
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleToggleActive = () => {
        const token = cookies['token'];
        if (selectedRows.length === 0) {
            alert('الرجاء اختيار كود');
            return;
        }
        const confirmation = window.confirm('هل انت متاكد');
        if (confirmation) {
            axios
                .put(`${process.env.REACT_APP_URL}/houdix/seen/admin/codes`, { ids: selectedRows },{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
                )
                .then((response) => {
                    if (response.status === 200) {
                        setMessage('تمت العملية بنجاح')
                        handleClickAlert()
                    }
                })
                .catch((error) => {
                    setMessage('خطأ في العملية')
                    handleClickAlert()
                });
        }
    };

    const handleDeleteActionClick = (selectedRows, setMessage, handleClickAlert, messages, url) => {
        const token = cookies['token']
        if (selectedRows.length === 0) {
            alert(messages.a);
            return;
        }
        const confirmation = window.confirm(messages.c);
        if (confirmation) {
            axios
                .delete(url, {
                    data: { ids: selectedRows },
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        setMessage("تمت العملية بنجاح")
                        handleClickAlert()
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setMessage(messages.b);
                    handleClickAlert()
                });
        }
    };

    useEffect(() => {
        const token = cookies['token']
        // fetchData(url, setRows, setIsLoading, cookies);
        const fetchData = async (url, setRows, setIsLoading, setCsvRows, token) => {
            try {
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setRows(response.data);
                setIsLoading(false);
                if (setCsvRows) {
                    setCsvRows(response.data)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData(url, setRows, setIsLoading, cookies, token)
        if (qrCodeValues.length > 0) {
            let array = [];
            for (let i = 1; i < qrCodeValues.length; i++) {
                QRCode.toDataURL(qrCodeValues[i].code_content, (err, dataURL) => {
                    if (err) {
                        console.error(err);
                        return;
                    }
                    array.push({ codeURL: dataURL, name: qrCodeValues[i].code_name, QRCode: qrCodeValues[i].code_content, subjects: qrCodeValues[0].subjects, level: qrCodeValues[0].level })
                });
            }
            setQRCodeUrl(array);
        }

    }, [open]);

    if (isLoading) {
        return (
            <div style={{ display: 'flex', height: "100vh", justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <div className='container'>
                <div className='title'>
                    <div>
                        <button>
                            <PDFDownloadLink
                                style={{ color: 'black', textDecoration: "none" }}
                                document={<QrCode qrCodeValues={qrCodeUrl} />}
                                fileName="qr_codes.pdf"
                            >
                                {({ blob, url, loading, error }) =>
                                    loading ? 'PDF' : 'PDF'
                                }
                            </PDFDownloadLink>
                        </button>
                        <button>
                            <CSVLink style={{ textDecoration: "none", color: 'black' }} data={csvRows} headers={codeHeaders} filename={"codes.csv"}>
                                CSV
                            </CSVLink>
                        </button>
                        <button onClick={handleAddActionClick}>إضافة</button>
                        <button onClick={handleToggleActive}>تعديل</button>
                        {selectedRows.length !== 0 && <button onClick={() => { handleDeleteActionClick(selectedRows, setMessage, handleClickAlert, messages, url) }}>حذف</button>}
                    </div>
                    <div>
                        <span className='main-title'>الأكواد</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ height: 'calc(100vh - 130px)', width: '100%' }}>
                            <DataGrid
                                sx={{ ".MuiDataGrid-columnHeaderTitle": { fontWeight: "700" }, ".MuiSvgIcon-root": { color: "#240259" }, '.MuiTablePagination-root': { direction: 'ltr' } }}
                                hideFooter={false}
                                rows={rows}
                                columns={codeDataGrid(ModeEditIcon, handleEditActionClick)}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 9,
                                        },
                                    },
                                }}
                                checkboxSelection
                                onRowSelectionModelChange={(itm) => {
                                    let array = [];
                                    const selectedRowsData = itm.map((id) => rows.find((row) => row.id === id));
                                    // const selectedRowNames = selectedRowsData.map((row) => row.code_content);
                                    for (let i = 0; i < selectedRowsData.length; i++) {
                                        QRCode.toDataURL(selectedRowsData[i].code_content, (err, dataURL) => {
                                            if (err) {
                                                console.error(err);
                                                return;
                                            }
                                            array.push({ codeURL: dataURL, name: selectedRowsData[i].code_name, QRCode: selectedRowsData[i].code_content, subjects: selectedRowsData[i].subjects, level: selectedRowsData[i].level });
                                        });
                                    }
                                    setQRCodeUrl(array);
                                    setSelectedRows(itm);
                                    setCsvRows(selectedRowsData);
                                }}
                                pageSizeOptions={[9]}
                                disableRowSelectionOnClick
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'even'
                                }
                                style={{ border: '5px solid #DFDFDF', borderRadius: '32px 32px 0 0 ', direction: "rtl", borderBottom: 'none', backgroundColor: "#FFFFFF" }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {addOpen && <AddCode handleClose={handleAddClose} handleClickAlert={handleClickAlert} setQRCodeValues={setQRCodeValues} setMessage={setMessage} />}
            {editOpen && <EditCodes handleClose={handleEditClose} selectedRows={selectedItem} handleClickAlert={handleClickAlert} setMessage={setMessage} />}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseAlert} >
                <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%', backgroundColor: '#240259', color: 'white' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Codes
