import React, { useEffect, useState } from "react";
import "../table.css";
import { DataGrid } from "@mui/x-data-grid";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddSub2 from "./AddSub2";
import EditSub2 from "./EditSub2";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import {
  subSubject2DataGrid,
  subSubject2Headers,
} from "../../constant/dataGrid";
import { CSVLink } from "react-csv";
import { useCookies } from "react-cookie";
import axios from "axios";
import ShowSubjects from "../subjects/ShowSubject";
import SortIcon from "@mui/icons-material/Sort";

const url = `${process.env.REACT_APP_URL}/houdix/seen/admin/sub-subjects/sub`;
const messages = {
  a: "الرجاء اختيار تصنيف",
  c: "هل انت متاكد انك تريد الحذف",
  b: "خطأ في العملية",
};

const SubSubjects2 = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [message, setMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [showOpen, setShowOpen] = React.useState(false);
  const [addOpen, setAddOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [getUrl, setGetUrl] = React.useState("");
  const [sortUrl, setSortUrl] = React.useState("");
  const [title, setTitle] = React.useState("");

  const handleAddOpen = () => setAddOpen(true);
  const handleAddClose = () => setAddOpen(false);
  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);
  const handleShowOpen = () => setShowOpen(true);
  const handleShowClose = () => setShowOpen(false);

  const handleAddActionClick = () => {
    handleAddOpen();
  };

  const handleShowActionClick = (row, getUrl, sortUrl, title) => {
    setTitle(title);
    setSortUrl(sortUrl);
    setGetUrl(getUrl);
    setSelectedItem(row);
    handleShowOpen();
  };

  const handleEditActionClick = (row) => {
    setSelectedItem(row);
    handleEditOpen();
  };

  const handleClickAlert = () => {
    setOpen(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // const handleDeleteActionClick = (selectedRows, setMessage, handleClickAlert, messages, url) => {
  //     const token = cookies['token']
  //     if (selectedRows.length === 0) {
  //         alert(messages.a);
  //         return;
  //     }
  //     const confirmation = window.confirm(messages.c);
  //     if (confirmation) {
  //         axios
  //             .delete(url, {
  //                 data: { ids: selectedRows },
  //                 headers: {
  //                     'Authorization': `Bearer ${token}`
  //                 }
  //             })
  //             .then((response) => {
  //                 if (response.status === 200) {
  //                     setMessage("تمت العملية بنجاح")
  //                     handleClickAlert()
  //                 }
  //             })
  //             .catch((error) => {
  //                 console.log(error);
  //                 setMessage(messages.b);
  //                 handleClickAlert()
  //             });
  //     }
  // };
  const handleDeleteActionClick = (
    selectedRows,
    setMessage,
    handleClickAlert,
    messages,
    url
  ) => {
    const token = cookies["token"];
    if (selectedRows.length === 0) {
      alert(messages.a);
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_URL}/houdix/seen/admin/sub-subjects/check`,
        { ids: selectedRows },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (!response.data) {
          const confirmation = window.confirm(
            "التصنيف يحتوي على اسئلة هل تريد الحذف"
          );
          if (confirmation) {
            axios
              .delete(url, {
                data: { ids: selectedRows },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((response) => {
                if (response.status === 200) {
                  setMessage("تمت العملية بنجاح");
                  handleClickAlert();
                }
              })
              .catch((error) => {
                console.log(error);
                setMessage(messages.b);
                handleClickAlert();
              });
          } else {
            return;
          }
        } else {
          const confirmation = window.confirm(messages.c);
          if (confirmation) {
            axios
              .delete(url, {
                data: { ids: selectedRows },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((response) => {
                if (response.status === 200) {
                  setMessage("تمت العملية بنجاح");
                  handleClickAlert();
                }
              })
              .catch((error) => {
                console.log(error);
                setMessage(messages.b);
                handleClickAlert();
              });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setMessage(messages.b);
        handleClickAlert();
      });
  };

  useEffect(() => {
    const token = cookies["token"];
    // fetchData(url, setRows, setIsLoading, cookies);
    const fetchData = async (url, setRows, setIsLoading, setCsvRows, token) => {
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRows(response.data);
        setIsLoading(false);
        if (setCsvRows) {
          setCsvRows(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData(url, setRows, setIsLoading, cookies, token);
  }, [open]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="title">
          <div>
            <button onClick={handleAddActionClick}>إضافة</button>
            <button>
              <CSVLink
                style={{ textDecoration: "none", color: "black" }}
                data={rows}
                headers={subSubject2Headers}
                filename={"sub-subjects.csv"}
              >
                CSV
              </CSVLink>
            </button>
            {selectedRows.length !== 0 && (
              <button
                onClick={() => {
                  handleDeleteActionClick(
                    selectedRows,
                    setMessage,
                    handleClickAlert,
                    messages,
                    `${process.env.REACT_APP_URL}/houdix/seen/admin/sub-subjects`
                  );
                }}
              >
                حذف
              </button>
            )}
          </div>
          <div>
            <span className="main-title">تصنيفات فرعية</span>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ height: "calc(100vh - 130px)", width: "100%" }}>
              <DataGrid
                sx={{
                  ".MuiDataGrid-columnHeaderTitle": { fontWeight: "700" },
                  ".MuiSvgIcon-root": { color: "#240259" },
                  ".MuiTablePagination-root": { direction: "ltr" },
                }}
                hideFooter={false}
                rows={rows}
                columns={subSubject2DataGrid(
                  ModeEditIcon,
                  handleEditActionClick,
                  handleShowActionClick,
                  SortIcon
                )}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 9,
                    },
                  },
                }}
                checkboxSelection
                pageSizeOptions={[9]}
                onRowSelectionModelChange={(itm) => setSelectedRows(itm)}
                disableRowSelectionOnClick
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even"
                }
                style={{
                  border: "5px solid #DFDFDF",
                  borderRadius: "32px 32px 0 0 ",
                  direction: "rtl",
                  borderBottom: "none",
                  backgroundColor: "#FFFFFF",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {addOpen && (
        <AddSub2
          handleClose={handleAddClose}
          handleClickAlert={handleClickAlert}
          setMessage={setMessage}
        />
      )}
      {editOpen && (
        <EditSub2
          handleClose={handleEditClose}
          selectedRows={selectedItem}
          handleClickAlert={handleClickAlert}
          setMessage={setMessage}
        />
      )}
      {showOpen && (
        <ShowSubjects
          handleClose={handleShowClose}
          selectedRows={selectedItem}
          handleClickAlert={handleClickAlert}
          setMessage={setMessage}
          getUrl={getUrl}
          sortUrl={sortUrl}
          title={title}
        />
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: "100%", backgroundColor: "#240259", color: "white" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SubSubjects2;
