import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { useCookies } from 'react-cookie';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: "50px",
    // height: '500px',
    textAlign: 'center',
    boxShadow: 24,
    p: '50px 20px',


};

export default function ShowPhoto({ handleClose, handleClickAlert, setMessage, selectedRows }) {

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const [isLoading, setIsLoading] = React.useState(false);

    const [data, setData] = React.useState({
        questions: [],
        answers: [],
    });

    React.useEffect(() => {
        const fetchProvinces = async (setData, url) => {
            const token = cookies['token']
            try {
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching provinces:', error);
            }
        };
        fetchProvinces(setData, `${process.env.REACT_APP_URL}/houdix/seen/admin/get-all-for-photo`);
    }, []);

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {!isLoading ? (
                        <div>
                            <img style={{ width: "100%" }} src={`${process.env.REACT_APP_URL}/${selectedRows.url}`} alt="" />
                        </div>
                    ) : (
                        <div style={{ display: 'flex', height: "300px", justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
}