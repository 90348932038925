import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import axios from "axios";
import { useCookies } from "react-cookie";
import { CircularProgress } from "@mui/material";

const Login = () => {
  const [login, setLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [register, setRegister] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [data, setData] = useState({
    email: "",
    password: "",
    user_name: "",
  });

  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/houdix/seen/admin/login`,
        data
      );

      if (response.status === 200) {
        setIsLoading(false);
        setCookie("token", response.data.token, {
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
        });
        // Redirect to the home page or any other protected route
        navigate("/", { replace: true });
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.response.data.message);
    }
  };

  const handelRegister = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/houdix/seen/admin/register`,
        data
      );

      if (response.status === 200) {
        localStorage.setItem("manager_id", response.data.id);
        setError("");
        setRegister(false);
      }
    } catch (error) {
      setError(error);
    }
  };

  const handelCode = async (e) => {
    e.preventDefault();
    const manager_id = localStorage.getItem("manager_id");

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/houdix/seen/admin/verify/${manager_id}
        )}`,
        { code }
      );
      if (response.status === 200) {
        setError("");
        setLogin(true);
      }
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  return (
    <div className="main">
      <div className="con-form">
        <div className="logo-container">
          <div className="ellipse1">
            <div className="ellipse2">
              <div className="groub1">
                <div className="groub2">
                  <div className="vector1">
                    <div className="vector2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isLoading ? (
          <>
            <form onSubmit={handleSubmit}>
              {login ? (
                <>
                  <label htmlFor="username">email</label>
                  <div>
                    <input
                      className="input-field"
                      type="email"
                      name="email"
                      id="username"
                      value={data.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <label htmlFor="password">Password</label>
                  <div>
                    <input
                      className="input-field"
                      type="password"
                      name="password"
                      id="password"
                      value={data.password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        margin: "0 0 10px 0",
                        fontSize: "14px",
                      }}
                    >
                      create account:{" "}
                      <span
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => {
                          setRegister(true);
                          setLogin(false);
                          setError("");
                        }}
                      >
                        register
                      </span>{" "}
                    </p>
                  </div>
                  <div>
                    <p
                      style={{
                        margin: "0 0 10px 0",
                        fontSize: "14px",
                      }}
                    >
                      verification account:{" "}
                      <span
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => {
                          setRegister(false);
                          setLogin(false);
                          setError("");
                        }}
                      >
                        verify
                      </span>{" "}
                    </p>
                  </div>
                  <button className="button" type="submit">
                    Login
                  </button>
                </>
              ) : register ? (
                <>
                  <label htmlFor="username">user name</label>
                  <div>
                    <input
                      className="input-field"
                      type="text"
                      name="user_name"
                      id="username"
                      value={data.user_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <label htmlFor="username">email</label>
                  <div>
                    <input
                      className="input-field"
                      type="email"
                      name="email"
                      id="username"
                      value={data.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <label htmlFor="password">Password</label>
                  <div>
                    <input
                      className="input-field"
                      type="password"
                      name="password"
                      id="password"
                      value={data.password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        margin: "0 0 10px 0",
                        fontSize: "14px",
                      }}
                    >
                      already have an account:{" "}
                      <span
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => {
                          setRegister(false);
                          setLogin(true);
                          setError("");
                        }}
                      >
                        login
                      </span>{" "}
                    </p>
                  </div>
                  <button className="button" onClick={(e) => handelRegister(e)}>
                    register
                  </button>
                </>
              ) : (
                <>
                  <label htmlFor="username">verification code</label>
                  <div>
                    <input
                      className="input-field"
                      type="text"
                      name="code"
                      id="username"
                      value={data.code}
                      onChange={(e) => setCode(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <p style={{ margin: "0 0 10px 0" }}>
                      already have an account:{" "}
                      <span
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => {
                          setRegister(false);
                          setLogin(true);
                          setError("");
                        }}
                      >
                        login
                      </span>{" "}
                    </p>
                  </div>
                  <button className="button" onClick={handelCode}>
                    verify
                  </button>
                </>
              )}
              {error && (
                <p style={{ color: "red", marginBottom: "0px" }}>{error}</p>
              )}
            </form>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              height: "300px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
