import React, { useEffect, useState } from 'react'
import '../table.css'
import { DataGrid } from '@mui/x-data-grid'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import axios from 'axios';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { CircularProgress } from '@mui/material';
import AddQuestions from './AddQuestions';
import EditQuestions from './EditQuestions';
import { CSVLink } from 'react-csv';
import { questionDataGrid, studentHeaders } from '../../constant/dataGrid';
import { formatCreatedAt } from '../../helpers/public';
import { useCookies } from 'react-cookie';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Questions = () => {

    const [cookies, setCookie, removeCookie] = useCookies(['token']);


    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState('none')
    const [open, setOpen] = React.useState(false);
    const [addOpen, setAddOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [rows, setRows] = useState([]);
    const [download, setDownload] = useState([]);
    const [selectedDownload, setSelectedDownload] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [x, setX] = useState({});

    const handleAddOpen = () => setAddOpen(true);
    const handleAddClose = () => setAddOpen(false);
    const handleEditOpen = () => setEditOpen(true);
    const handleEditClose = () => setEditOpen(false);

    const handleAddActionClick = () => {
        handleAddOpen();
    };

    const handleEditActionClick = (row) => {
        setSelectedItem(row)
        handleEditOpen();
    };

    const handleClickAlert = () => {
        setOpen(true);
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleDeleteActionClick = () => {
        const token = cookies['token'];
        if (selectedRows.length === 0) {
            alert('Please select rows to delete.');
            return;
        }
        const confirmation = window.confirm('Are you sure you want to delete this row?');
        if (confirmation) {
            axios
                .delete(`${process.env.REACT_APP_URL}/houdix/seen/admin/questions`, {
                    data: { ids: selectedRows },
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        setMessage('تم الحذف بنجاح')
                        handleClickAlert()
                    }
                })
                .catch((error) => {
                    setMessage('خطأ في العملية')
                    handleClickAlert()
                });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
        const token = cookies['token'];
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/houdix/seen/admin/questions`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setRows(response.data);
                setIsLoading(false);
                const exportData = await axios.get(`${process.env.REACT_APP_URL}/houdix/seen/admin/questions/statistics`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                // const [exportData, response] = await Promise.all([exportDataPromise, responsePromise]);
                setDownload(exportData.data);
                setSelectedDownload(exportData.data);
                setLoading('inline')
                setX({
                    checkboxSelection: "checkboxSelection"
                })

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [open]);

    if (isLoading) {
        return (
            <div style={{ display: 'flex', height: "100vh", justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <div className='container'>
                <div className='title'>
                    <div>
                        <button style={{ display: loading }}>
                            <CSVLink style={{ textDecoration: "none", color: 'black' }} data={selectedDownload} headers={studentHeaders} filename={"questions.csv"}>
                                CSV
                            </CSVLink>
                        </button>
                        <button onClick={handleAddActionClick}>إضافة</button>
                        {selectedRows.length !== 0 && <button onClick={handleDeleteActionClick}>حذف</button>}
                    </div>
                    <div>
                        <span className='main-title'>الأسئلة</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ height: 'calc(100vh - 130px)', width: '100%' }}>
                            <DataGrid
                                sx={{ ".MuiDataGrid-columnHeaderTitle": { fontWeight: "700" }, ".MuiSvgIcon-root": { color: "#240259" }, '.MuiTablePagination-root': { direction: 'ltr' } }}
                                hideFooter={false}
                                rows={rows}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 9,
                                        },
                                    },
                                }}
                                columns={questionDataGrid(ModeEditIcon, handleEditActionClick, formatCreatedAt)}
                                {...x}
                                pageSizeOptions={[9]}
                                onRowSelectionModelChange={(itm) => {
                                    const selectedRowsData = itm.map((id) => download.find((row) => row.question.id === id));
                                    setSelectedDownload(selectedRowsData);
                                    setSelectedRows(itm)
                                }}
                                disableRowSelectionOnClick
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'even'
                                }
                                style={{ border: '5px solid #DFDFDF', borderRadius: '32px 32px 0 0 ', direction: "rtl", borderBottom: 'none', backgroundColor: "#FFFFFF" }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {addOpen && <AddQuestions handleClose={handleAddClose} handleClickAlert={handleClickAlert} setMessage={setMessage} />}
            {editOpen && <EditQuestions handleClose={handleEditClose} selectedRows={selectedItem} handleClickAlert={handleClickAlert} setMessage={setMessage} />}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseAlert} >
                <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%', backgroundColor: '#240259' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Questions
