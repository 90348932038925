import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import '../inputs.css';
import { studentHeaders } from '../../constant/dataGrid';
import { useCookies } from 'react-cookie';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: "50px",
    // height: '500px',
    textAlign: 'center',
    boxShadow: 24,
    p: '30px 20px 0px 20px',


};

export default function EditStudents({ handleClose, selectedRows, handleClickAlert }) {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        const fetchData = async () => {
        const token = cookies['token'];
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/houdix/seen/admin/questions/statistics/${selectedRows.id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    setData(response.data);
                }
            } catch (error) {
                console.error(error.message);
            }
        };
        fetchData();
    }, []);

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography sx={{ fontFamily: 'Almarai' }} id="modal-modal-title" variant="h3" component="h2">
                        تصدير مستخدم
                    </Typography>
                    <div className='center'>
                        <form action="">
                            <div className="inputbox">
                                <button onClick={() => { handleClose(); handleClickAlert() }}>
                                    <CSVLink className='hoverhover' style={{ textDecoration: "none", color: 'black' }} data={data} headers={studentHeaders} filename={`${selectedRows.username}.csv`}>
                                        تنزيل
                                    </CSVLink>
                                </button>
                                <button onClick={handleClose}>
                                    إلغاء
                                </button>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}